/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Helmet from 'react-helmet'
import Header from "./header"
import { useStaticQuery, graphql } from "gatsby"
import './layout.scss';



const Layout = ({ children }) => {
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
          description
        }
      }
    }
  `)

return(
  <div>
    <Helmet 
      title = {data.site.siteMetadata?.title}
      meta = {[
        { name: 'description', content: 'the geeks allright' },
        { name: 'keywords', content: 'ameth, blogger, developer, designer, lifestyle, minamilism, productivity' },
      ]}
    />
    <Header siteTitle = {data.site.siteMetadata?.title} />
    <div className="page-content">
      <div className="wrapper">
        {children}
      </div>
    </div>
  </div>
)
  
}


export default Layout
