import { Link } from "gatsby"
import React from "react"
import './header.scss'

const Header = ({siteTitle}) => (  
  <header className="site-header">
    <div className="wrapper">
      <Link className="site-title" to="/">{siteTitle}</Link>
      <nav className="site-nav">
        <div className="trigger">
          <Link className="page-link" to="/">Home</Link>
          <Link className="page-link" to="/about">About</Link>
        </div>
      </nav>
    </div>
  </header>
)

export default Header


// <h1>
// <Link to="/">
//   {siteTitle}
// </Link>
// </h1>

// <li class="nav-item">
//               <Link to="/" class="nav-link active" aria-current="page" href="#">Home</Link>
//             </li>
//             <li class="nav-item">
//               <Link to="/about" class="nav-link" href="#">Features</Link>
//             </li>